<template>
  <div class="container">
        <Header />

  </div>
</template>


<script>
import { ImagePreview } from 'vant';
import { Toast } from 'vant'
import Header from '../components/Header/index.vue'

  export default {
      

    name:"Main",
      data() {
        return {

        };
      },

    mounted(){
      // console.log(this)
      
    },

    components:{
      Header
    },


  }
</script>
  
<style scoped>

</style>